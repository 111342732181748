import {createRouter, createWebHistory} from "vue-router";

const routes = [
  {
    name: "home",
    path: '/',
    component: () => import("/js/components/ComingSoon/ComingSoon.vue"),
  },
  // {
  //   name: "demo",
  //   path: '/demo',
  //   component: () => import("/js/components/Demo/Demo.vue"),
  // },
  {
    path: '/:catchAll(.*)*',
    name: "PageNotFound",
    redirect: {name: "home"}
    // component: () => import("/js/components/PageNotFound.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router